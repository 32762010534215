import React, {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import axios, {baseUrl, removeStorage} from "../../../../utils/axios";
import {Space, Spin} from "antd";
import {useNavigate} from "react-router-dom";

ChartJS.register(
    Title,
    Tooltip,
    LineElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
);
function LineChart(props) {
    const navigate = useNavigate()
    var ctx = document.createElement("canvas").getContext("2d");
    const [labelData, setLabelData] = useState([]);
    const [dataChart, setDataChart] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true);
        const topSites = async () => {
            try {
                const res = await axios.get(baseUrl + "/responseLineGraph?type=" + props.timeSelected + "&user_id=" + localStorage.getItem("A55_Portal"));
                setLabelData(res.data['labels'])
                setDataChart(res.data['data'])
            } catch (err) {
                if (err.response.status === 401) {
                    removeStorage()
                    navigate("/login")
                }
            }
            setLoading(false)
        };
        topSites();
    }, [props.timeSelected]);
    var gradient = ctx.createLinearGradient(600, 600, 600, 0);
    gradient.addColorStop(1, "rgba(8, 133, 65, 1)");
    gradient.addColorStop(0.5, "rgba(253, 253, 253, 0.5)");
    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: null,
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    stepSize: 1
                }
            }
        }
    };
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: loading?"center":"flex-start",
            width: "100%",
            height: "300px",
            padding: "10px"
        }}>
            {loading ?
                <Space size="large">
                    <Spin size="large"/>
                </Space>
                :
                <Line options={options} data={
                    {
                        labels: labelData,
                        datasets: [
                            {
                                label: 'Response time',
                                data: dataChart,
                                borderColor: "#088541",
                                fill: true,
                                backgroundColor: gradient,
                            },
                        ],
                    }
                }/>
            }
        </div>
    );
};
export default LineChart;
