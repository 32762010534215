import {Routes, Route, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Navigation from "./Navigation";

import Login from "../javascript/Forms/Login";
import SelectForm from "../javascript/Forms/SelectForm";
import SiteForm from "../javascript/Forms/SiteForm";
import Network from "../javascript/Forms/Network";
import SelectArea from "../javascript/Forms/SelectArea";
import Navbar from "../Components/Navbar";
import Dashboard from "../javascript/Admin/Dashboard";
import ShowUser from "../javascript/Admin/ShowUser";
import FormFile from "../javascript/Admin/FormFile";
import AddLocation from "../javascript/Admin/AddLocation";
import GrantAccess from "../javascript/Admin/GrantAccess";
// import { useEffect, useState } from "react";
// import NavbarMain from "./NavBar";

// import { UserContext } from "../Context/UserContext";

const SecondNavBar = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const [configData, setConfigData] = useState(null);
    const [token, setToken] = useState("");
    const [userData, setUserData] = useState(null);
    // useEffect(() => {
    //   let userAllData = localStorage.getItem("user");
    //   let config = localStorage.getItem("monetx_configuration");
    //   setUserData(JSON.parse(userAllData));
    //   setConfigData(JSON.parse(config));
    // }, []);
    // const [loading, setLoading] = useState(1);

    // useEffect(() => {
    //   // debugger;
    //   if (loading === 1) {
    //     window.location.reload();
    //     // setLoading(loading + 1);
    //   } else {
    //   }
    // }, [token]);
    // useEffect(() => {
    //   let mainkey = localStorage.getItem("monetx_token");
    //   if (mainkey) {
    //     setToken(mainkey);
    //     // window.location.reload();
    //   } else {
    //     navigate("/login");
    //   }
    // }, [pathname]);

    return (
        <div>
            {/* <UserContext.Provider value={userData}> */}
            {/* {pathname !== "/login" && (
        <>
          {/* <MyFirst i18n={props.i18n} /> */}
            {/* <Navigation />
        </> */}
            {/* )} */}
            {/* <Navigation /> */}
            {/* <MyFirst />
      {/* <FirstNavBar /> */}
            <Navigation/>
            {/* {this.props.location.pathname !== "/login" && <Login />} */}
            <Routes>
                {token ? (
                    <>
                        {/*<Route path='/' exact element={<Login/>} /> */}
                        <Route path="/login" exact element={<Login/>}/>
                        <Route path="/SelectForm" exact element={<SelectForm/>}/>
                        <Route path="/SelectArea" exact element={<SelectArea/>}/>
                        <Route path="/SiteForm" exact element={<SiteForm/>}/>
                        <Route path="/Network" exact element={<Network/>}/>
                        <Route path="/Navbar" exact element={<Navbar/>}/>
                        <Route path="/Dashboard" exact element={<Dashboard/>}/>
                        <Route path="/ShowUser" exact element={<ShowUser/>}/>
                        <Route path="/FormFile" exact element={<FormFile/>}/>
                        <Route path="/GrantAccess" exact element={<GrantAccess/>}/>
                        <Route path="/AddLocation" exact element={<AddLocation/>}/>
                    </>
                ) : (
                    <Route path="/login" element={<Login/>}/>
                )}
            </Routes>
            {/* </UserContext.Provider> */}
        </div>
    );
};

export default SecondNavBar;
