import styled from "styled-components";

export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../image/BG.png");
  /* border: 1px solid red; */
`;

export const StyledDashboardContainer = styled.div`
  /* border: 1px solid green; */
  padding-top: 2.5%;
  margin-top: 5px;
  margin-left: 3%;
  margin-right: 3%;
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 210px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding-top: 0px;
  margin-bottom: 10px;
`;

export const StyledIcon = styled.div`
  width: 53px;
  height: 53px;
  background: rgba(198, 251, 137, 0.27);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCardText = styled.div`
  margin-top: 20px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #a4a4a4;
`;

export const StyledNumber = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0.03em;
  color: #000000;
`;

export const StyledGraphCard = styled.div`
  height: 380px;
  width: 100%;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const StyledHeading = styled.div`
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.03em;
  margin-top: 3px;
  padding-left: 15px;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const StyledStatusFlexBox = styled.div`
  display: flex;
  flex: 3;
  flex-direction: row;
  float: right;
  margin-top: -30px;
  margin-right: 50px;
`;

export const StyledImg = styled.div`
  line-height: 27px;
`;

export const A55Block = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 22px;
  background: #a1b0fe;
  margin-right: 5px;
`;
export const DPole = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 22px;
  background: #9dd9ec;
  margin-right: 5px;
`;
export const Raise = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 22px;
  background: #38cbaa;
  margin-right: 5px;
`;
export const Network = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 22px;
  background: #abe590;
  margin-right: 5px;
`;

export const StyleGraphCardHeading = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  @media (max-width: 500px) {
    display: block;
  }
`;
export const StyleGraphCardItem1 = styled.div`
  display: flex;
  flex: 1;
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
`;
export const StyledBlockText = styled.div`
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.03em;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 20px;
  margin-left: 20px;
  width: 100%;
`;
export const StyledDropDown = styled.select`
  margin-top: 3px;
  line-height: 27px;
  border: none;
  height: 25px;
  background: rgba(151, 151, 151, 0.06);
  border-radius: 5px;
  width: 150px;
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #979797;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
`;

export const StyledTimeContainer = styled.div`
  height: 380px;
  width: 100%;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  // margin-left: 10px;
`;

export const StyledDropDownTime = styled.select`
  border: none;
  height: 25px;
  background: rgba(151, 151, 151, 0.06);
  border-radius: 5px;
  font-family: "Satoshi", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #979797;
  float: right;
  margin-top: -25px;
  margin-right: 20px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #5c8246;
    background-color: #ffffff;
  }
  width: 150px;
`;

export const StyledMapContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  margin-top: 30px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  margin-right: 20%;
  float: right;
`;

export const StyledContent1 = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  height: 50px;
  border-left: 2px solid #d9d9d9;
`;

export const StyledContentText = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const StyledContentCount = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0.03em;
  color: #3d9f46;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
