import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./containers/login";
import SelectForm from "./containers/user/selectForm";
import SiteForm from "./containers/user/siteForm";
import Network from "./containers/user/networkForm";
import SelectArea from "./containers/user/selectArea";
import Dashboard from "./containers/admin/dashboard";
import ShowUser from "./containers/admin/showuser";
import FormFile from "./containers/admin/formdata";
import AddLocation from "./containers/admin/addlocation";
import GrantAccess from "./containers/admin/grantaccess";
import SuperAdminDashboard from "./containers/superAdmin/dashboard";
import { useEffect, useState } from "react";
import NavbarMain from "./NavBar";
import { ForgotPassword } from "./containers/login/forgotPassword";
import ChangePassword from "./containers/login/changePassword";
import DPoleForm from "./containers/user/dPoleForm";
import TreeCuttingForm from "./containers/user/treeCuttingForm";
import HyperOpticForm from "./containers/user/hyperOpticForm";
import MapLocation from "./containers/user/treeCuttingForm/Items/mapLocation";
function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [userid, setUserid] = useState("");
  const routeList = [
    "/selectform",
    "/selectarea",
    "/siteform",
    "/network",
    "/dashboard",
    "/showuser",
    "/formfile",
    "/grantaccess",
    "/addlocation",
    "/superadmindashboard",
    "/monetx",
    "/d-pole",
    "/tree-cutting",
    "/hyper-optic",
    "/map",
  ];
  useEffect(() => {
    const user = localStorage.getItem("A55_Portal");
    const type = localStorage.getItem("A55_Portal_type");
    if (user) {
      setUserid(user);
      if (!routeList.includes(location.pathname.toLowerCase())) {
        navigate("/");
      }
      if (type === "User" && location.pathname === "/") {
        navigate("/SelectForm");
      } else if (
        (type === "Admin" || type === "Super Admin") &&
        location.pathname === "/"
      ) {
        navigate("/dashboard");
      } else if (type === "SuperAdmin" && location.pathname === "/") {
        navigate("/superadmindashboard");
      }
      if (type === "Admin" || type === "Super Admin") {
        if (
          //user routes
          location.pathname.toLowerCase() === "/selectform" ||
          location.pathname.toLowerCase() === "/selectarea" || //super admin routes
          location.pathname.toLowerCase() === "/superadmindashboard"
        ) {
          navigate("/dashboard");
        }
      }

      if (type === "User") {
        if (
          //admin routes
          location.pathname.toLowerCase() === "/dashboard" ||
          location.pathname.toLowerCase() === "/showuser" ||
          //   location.pathname.toLowerCase() === "/formfile" ||
          location.pathname.toLowerCase() === "/grantaccess" ||
          location.pathname.toLowerCase() === "/addlocation" || //super admin routes
          location.pathname.toLowerCase() === "/superadmindashboard"
        ) {
          navigate("/selectform");
        }
      }

      if (type === "SuperAdmin") {
        if (
          //user routes
          location.pathname.toLowerCase() === "/selectform" ||
          location.pathname.toLowerCase() === "/selectarea" || //admin routes
          location.pathname.toLowerCase() === "/dashboard" ||
          location.pathname.toLowerCase() === "/showuser" ||
          location.pathname.toLowerCase() === "/formfile" ||
          location.pathname.toLowerCase() === "/grantaccess" ||
          location.pathname.toLowerCase() === "/addlocation"
        ) {
          navigate("/superadmindashboard");
        }
      }
    } else {
      // navigate("/login");
    }
  }, [location.pathname, localStorage.getItem("A55_Portal_type")]);
  return (
    <>
      {location.pathname !== "/login" &&
        location.pathname !== "/monetx" &&
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/change-password" && <NavbarMain />}
      <Routes>
        {userid ? (
          <>
            <Route path="/login" exact element={<Login />} />
            <Route path="/SelectForm" exact element={<SelectForm />} />
            <Route path="/SelectArea" exact element={<SelectArea />} />
            <Route path="/SiteForm" exact element={<SiteForm />} />
            <Route path="/Network" exact element={<Network />} />
            <Route path="/d-pole" exact element={<DPoleForm />} />
            <Route path="/tree-cutting" exact element={<TreeCuttingForm />} />
            <Route path="/hyper-optic" exact element={<HyperOpticForm />} />
            <Route path="/Dashboard" exact element={<Dashboard />} />
            <Route path="/ShowUser" exact element={<ShowUser />} />
            <Route path="/FormFile" exact element={<FormFile />} />
            <Route path="/GrantAccess" exact element={<GrantAccess />} />
            <Route path="/AddLocation" exact element={<AddLocation />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route path="/change-password" exact element={<ChangePassword />} />
            <Route path="/map" exact element={<MapLocation />} />
            <Route
              path="/superadmindashboard"
              exact
              element={<SuperAdminDashboard />}
            />
            {/* <Route path="/dashboard" exact element={<SuperAdminDashboard />} /> */}
          </>
        ) : (
          <>
            <Route path="/login" exact element={<Login />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route path="/change-password" exact element={<ChangePassword />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
