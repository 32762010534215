import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import arrow from "../image/arrow.svg";
import {
  LeftContainer,
  LeftContainer2,
  MainLogo,
  NavbarContainer,
  NavbarExtendedContainer,
  NavbarInnerContainer,
  NavbarLink,
  NavbarLinkContainer,
  NavbarLinkExtended,
  OpenLinksButton,
  RightBox,
  RightContainer,
  Rprof,
  Rtext,
} from "./Navigation.styled.js";
import { Icon } from "@iconify/react";
import logo from "../image/new_logo.png";
import SuperAdminModal from "../containers/superAdmin/modals";
import OrgEditModal from "../containers/superAdmin/modals/editModal";
import dash from "./Assets/dash.svg";
import active_dashboard from "./Assets/active_dashboard.svg";
import showUserN from "./Assets/showuser.svg";
import showUser from "./Assets/showusern.svg";
import textN from "./Assets/text.svg";
import text from "./Assets/textn.svg";
import grant from "./Assets/grant.svg";
import grantN from "./Assets/grantn.svg";
import routingN from "./Assets/routing.svg";
import routing from "./Assets/routingn.svg";
import { Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const Navigation = () => {
  const { pathname } = useLocation();
  const [selectedOrg, setSelectedOrg] = useState(
    JSON.parse(localStorage.getItem("selected_org"))
  );
  const module = useLocation().pathname.split("/")[1];
  const name = localStorage.getItem("A55_name");
  const type = localStorage.getItem("A55_Portal_type");
  const [isSAModalVisible, setIsSAModalVisible] = useState(false);
  const [isOrgEditModalVisible, setIsOrgEditModalVisible] = useState(false);
  const [selectedOrganizationName, setSelectedOrganizationName] =
    useState(null);
  const [extendNavbar, setExtendNavbar] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    localStorage.getItem("lang");
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      window.location.reload();
    } else {
      setIsFirstLoad(false);
    }
  }, [isVisible]);

  const Logout = () => {
    localStorage.removeItem("A55_name");
    localStorage.removeItem("A55_Portal_type");
    localStorage.removeItem("A55_Portal");
    localStorage.removeItem("resp_id");
    localStorage.removeItem("form_selected");
    window.location.href = "/login";
  };
  return (
    <div
      style={{
        // paddingTop: "8px",
        // boxShadow: "rgb(38, 57, 77) 0px 5px 5px -7px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        // zIndex: "9999999",
      }}
    >
      {isOrgEditModalVisible ? (
        <OrgEditModal
          isModalVisible={isOrgEditModalVisible}
          setIsModalVisible={setIsOrgEditModalVisible}
        />
      ) : null}

      {isSAModalVisible ? (
        <SuperAdminModal
          setIsSAModalVisible={setIsSAModalVisible}
          editRecord={null}
        />
      ) : null}

      <NavbarContainer
        extendNavbar={extendNavbar}
        style={{
          border: "0px solid black",
        }}
      >
        <NavbarInnerContainer
          style={{
            border: "0px solid black",
          }}
        >
          <MainLogo
            src={logo}
            style={{
              marginLeft: "15px",
              marginBottom: "5px",
              border: "0px solid red",
            }}
          />
          {(type === "Admin" || type === "Super Admin") && (
            <LeftContainer
              style={{
                border: "0px solid green",
              }}
            >
              <NavbarLinkContainer>
                <NavbarLink
                  to="/dashboard"
                  activeStyle
                  style={{
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  {pathname === "/dashboard" ? (
                    <img
                      src={active_dashboard}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  ) : (
                    <img
                      src={dash}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  )}
                  Dashboard
                </NavbarLink>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavbarLink
                  to="/ShowUser"
                  activeStyle
                  style={{
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  {pathname === "/ShowUser" ? (
                    <img
                      src={showUser}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  ) : (
                    <img
                      src={showUserN}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  )}
                  Show User
                </NavbarLink>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavbarLink
                  to="/FormFile"
                  activeStyle
                  style={{
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  {module === "FormFile" ? (
                    <img
                      src={text}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  ) : (
                    <img
                      src={textN}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  )}
                  Form Data
                </NavbarLink>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavbarLink
                  to="/AddLocation"
                  activeStyle
                  style={{
                    display: "flex",
                    // width: "80px",
                    textAlign: "center",
                  }}
                >
                  {module === "AddLocation" ? (
                    <img
                      src={routing}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  ) : (
                    <img
                      src={routingN}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  )}
                  Add Location
                </NavbarLink>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavbarLink
                  to="/GrantAccess"
                  activeStyle
                  style={{
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  {module === "GrantAccess" ? (
                    <img
                      src={grant}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  ) : (
                    <img
                      src={grantN}
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        width: "20px",
                        height: "20px",
                        paddingTop: "1px",
                      }}
                    />
                  )}
                  Grant Access
                </NavbarLink>
              </NavbarLinkContainer>
              <OpenLinksButton
                onClick={() => {
                  setExtendNavbar((curr) => !curr);
                }}
              >
                {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
              </OpenLinksButton>
            </LeftContainer>
          )}
          {type === "SuperAdmin" && (
            <LeftContainer2
              style={{
                border: "0px solid green",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "space-evenly",
                  padding: "0 15px",
                  border: "0px solid black",
                  width: "30%",
                }}
              >
                {/* <div
                  style={{
                    border: "0px solid black",
                    fontWeight: "bolder",
                  }}
                >
                  {selectedOrg?.org_name}
                </div> */}
                <Button
                  style={{
                    border: "2px solid #3D9F46",
                    width: "30%",
                    color: "#3D9F46",
                    background: "transparent",
                    fontWeight: "bolder",
                    borderRadius: "5px",
                    fontSize: "12px",
                  }}
                  onClick={() => setIsOrgEditModalVisible(true)}
                >
                  Edit
                </Button>
              </div>
              <div
                style={{
                  border: "0px solid black",
                  color: "grey",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => setIsSAModalVisible(true)}
              >
                + Add Organization
              </div>
            </LeftContainer2>
          )}
          {type === "User" && (
            <LeftContainer2
              style={{
                border: "0px solid green",
              }}
            ></LeftContainer2>
          )}
          <RightContainer
            style={{
              // paddingTop: "12px",
              border: "0px solid black",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SettingOutlined
                style={{
                  fontSize: "25px",
                  marginRight: "20px",
                  color: "#B3B3B3",
                }}
              />

              <Icon icon="mi:notification" fontSize={"30px"} color="#B3B3B3" />
            </div>
            <div
              className="logout_btn"
              style={{
                background: "rgb(250,209,202)",
                borderRadius: 50,
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={arrow}
                onClick={Logout}
                width="15px"
                height="15px"
                style={{ cursor: "pointer" }}
              />
            </div>
            <RightBox>
              <Rtext>{name}</Rtext>
              <Rprof>{type}</Rprof>
            </RightBox>
          </RightContainer>
        </NavbarInnerContainer>
        {extendNavbar && (
          <NavbarExtendedContainer style={{ zIndex: "999" }}>
            <NavbarLinkExtended to="/">Dashboard</NavbarLinkExtended>
            <NavbarLinkExtended to="/ShowUser">Show User</NavbarLinkExtended>
            <NavbarLinkExtended to="/FormFile">Form Data</NavbarLinkExtended>
            <NavbarLinkExtended to="/AddLocation">
              Add Location
            </NavbarLinkExtended>
            <NavbarLinkExtended to="/GrantAccess">
              Grant Access
            </NavbarLinkExtended>
          </NavbarExtendedContainer>
        )}
      </NavbarContainer>
    </div>
  );
};

export default Navigation;
