import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const NavbarContainer = styled.nav`
  /* z-index: 99999999; */
  width: 100%;
  height: ${(props) => (props.extendNavbar ? "70px" : "80px")};
  /* border: 1px solid black; */
  background-color: white;
  box-shadow: rgb(38, 57, 77) 0px 5px 4px -7px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  @media (min-width: 700px) {
    height: 55px;
  }
`;

export const LeftContainer = styled.div`
  flex: 70%;
  display: flex;
  // border: 1px solid red;
  align-items: center;
  padding-left: 2%;
`;
export const LeftContainer2 = styled.div`
  flex: 70%;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
  align-items: center;
  padding: 0 2%;
`;

export const RightContainer = styled.div`
  /* flex: 30%; */
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  width: 30%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  /* border: 10px solid yellow; */
  /* border-top: 1px solid #fff; */
  display: flex;
  /* background-color: #ffffff; */
  /* align-item: center; */
`;

export const NavbarLinkContainer = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const NavbarLink = styled(Link)`
  color: #c5c5c5;
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;

  margin: 5px;
  &:hover {
    color: #71b626;
    font-weight: 700;
  }
  &.active {
    color: #71b626;
    border-bottom: 2px solid #71b626;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const NavbarLinkExtended = styled(Link)`
  color: #fff !important;
  font-size: 12px;
  // font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const Logo = styled.img`
  margin: 10px;
  max-width: 180px;
  height: auto;
`;

export const OpenLinksButton = styled.button`
  width: 70px;
  height: 65px;
  background: none;
  border: none;
  color: black;
  font-size: 45px;
  cursor: pointer;
  float: left;
  margin-top: -15px;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  z-index: 9999999999;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  @media (min-width: 900px) {
    display: none;
  }
`;
export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 12px;
`;

export const Rtext = styled.div`
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #2f3541;
  text-align: center;
`;
export const Rprof = styled.div`
  font-family: "Satoshi";
  /* float: left; */
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.075em;
  color: rgba(65, 68, 74, 0.36);
`;
export const MainLogo = styled.img`
  width: 110px;
  height: 60px;
  @media (max-width: 900px) {
    display: none;
  }
`;
